import { useState } from "react";
import { detectLanguage } from "utils";

function useLanguage<T extends string>(availableLanguages: T[]) {
  const [language, setLanguage] = useState(() =>
    detectLanguage(availableLanguages)
  );

  const changeLanguage = (chosenLanguage: T) => {
    localStorage.setItem("language", chosenLanguage);
    setLanguage(chosenLanguage);
  };

  return [language, changeLanguage] as const;
}

export default useLanguage;
