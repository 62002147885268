export default function detectLanguage<T extends string>(
  availableLanguages: T[]
): T {
  let userLanguage = localStorage.getItem("language");

  if (!userLanguage) {
    let browserLanguage = window.navigator.language;

    if (browserLanguage) {
      userLanguage = browserLanguage.split("-")[0];
    }
  }

  if (!userLanguage || !availableLanguages.includes(userLanguage as T)) {
    userLanguage = availableLanguages[0];
  }

  return userLanguage as T;
}
