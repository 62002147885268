import "styles/sections/contact/ContactPerson.scss";
import clsx from "clsx";
import { Contact } from "types";
import { ArrowRight } from "images-react";
import { MutableRefObject } from "react";
import { Texture } from "components";

// images
const ola_w337png = "images-react/contact/ola_w337.png";
const ola_w674png = "images-react/contact/ola_w674.png";
const ola_w337webp = "images-react/contact/ola_w337.webp";
const ola_w674webp = "images-react/contact/ola_w674.webp";
const milena_w295png = "images-react/contact/milena_w295.png";
const milena_w590png = "images-react/contact/milena_w590.png";
const milena_w295webp = "images-react/contact/milena_w295.webp";
const milena_w590webp = "images-react/contact/milena_w590.webp";

const images: { [key: string]: { size: number; png: string; webp: string }[] } =
  {
    ola: [
      { size: 337, png: ola_w337png, webp: ola_w337webp },
      { size: 674, png: ola_w674png, webp: ola_w674webp },
    ],
    milena: [
      { size: 295, png: milena_w295png, webp: milena_w295webp },
      { size: 590, png: milena_w590png, webp: milena_w590webp },
    ],
  };

function ContactPerson({ details, refer }: Props) {
  return (
    <li className="contact-person" ref={refer}>
      <p className="contact-person__role" lang="en">
        {details.role}
      </p>
      <p className="contact-person__name">
        <span className="contact-person__name-part">{details.firstName} </span>
        <span className="contact-person__name-part">{details.lastName}</span>
      </p>
      <p className="contact-person__email">
        <a
          className="contact-person__email-link underline-hover"
          href={"mailto:" + details.email}
        >
          <ArrowRight
            className="contact-person__arrow"
            role="presentation"
            aria-hidden="true"
          />
          <span className="contact-person__link-text">{details.email}</span>
        </a>
      </p>
      <picture>
        <source
          type="image/webp"
          srcSet={`${images[details.image][0].webp} ${
            images[details.image][0].size
          }w, ${images[details.image][1].webp} ${
            images[details.image][1].size
          }w`}
          sizes={`${images[details.image][0].size}px`}
        />
        <img
          src={images[details.image][1].png}
          srcSet={`${images[details.image][0].png} ${
            images[details.image][0].size
          }w, ${images[details.image][1].png} ${
            images[details.image][1].size
          }w`}
          sizes={`${images[details.image][0].size}px`}
          className={clsx("contact-person__image", details.image)}
          loading="lazy"
          decoding="async"
          alt=""
        />
      </picture>
      <Texture className="contact-person__texture" />
    </li>
  );
}

type Props = {
  details: Contact;
  refer: MutableRefObject<HTMLLIElement | null>;
};

export default ContactPerson;
