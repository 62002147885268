import { useEffect, useState } from "react";
import { Language } from "types";
import { formatNumber } from "utils";

const easeOutQuad = (t: number) => t * (2 - t);
const frameDuration = 1000 / 60;

function CountUpAnimation({
  children,
  animate,
  formatLang,
  duration = 2000,
}: Props) {
  let countTo: number;
  if (typeof children === "number") {
    countTo = children;
  } else {
    countTo = parseFloat(children.replace(",", "."));
  }

  const decimalsAfterComma = String(countTo).split(".").length - 1;

  const [count, setCount] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (animate && !done) {
      let frame = 0;
      const totalFrames = Math.round(duration / frameDuration);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        setCount(countTo * progress);

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);

      setDone(true);
    }
  }, [countTo, duration, animate, done]);

  const round = (number: number, decimals: number) =>
    Math.round(number * 10 ** decimals) / 10 ** decimals;

  let format = (number: number): number | string => number;
  if (formatLang !== undefined) {
    format = (number: number) => formatNumber(number, formatLang);
  }

  return (
    <span aria-live="polite" aria-busy={count === countTo ? "false" : "true"}>
      {format(round(count, decimalsAfterComma))}
    </span>
  );
}

type Props = {
  children: string | number;
  animate: boolean;
  formatLang?: Language;
  duration?: number;
  padSize?: number;
};

export default CountUpAnimation;
