export default function updateMetaData<T extends string>(data: MetaData<T>) {
  document.documentElement.lang = data.language;
  document.title = data.title;

  const ogTitle = document.querySelector("meta[property='og:title']");
  if (ogTitle) ogTitle.setAttribute("content", data.title);

  const description = document.querySelector("meta[name='description']");
  if (description) description.setAttribute("content", data.description);

  const ogDescription = document.querySelector(
    "meta[property='og:description']"
  );
  if (ogDescription) ogDescription.setAttribute("content", data.description);
}

type MetaData<T> = {
  language: T;
  title: string;
  description: string;
};
