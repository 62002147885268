import "styles/navigation/Header.scss";
import FocusTrap from "focus-trap-react";
import { useState } from "react";
import { LangChangeHandler, LangProp, Section, SectionState } from "types";
import { Logo, MenuButton, NavigationPanel } from "components/navigation";
import { general } from "translations";

function Header({
  lang,
  onLangChange,
  sections,
  currentSection,
  isPageTop,
  isHero,
}: Props) {
  const [navIsExpanded, setNavIsExpanded] = useState(false);
  const { indexLabel, closeLabel } = general[lang];
  const navigationPanelId = "navigation-panel";

  return (
    <header className="header">
      <Logo lang={lang} isPageTop={isPageTop} navIsExpanded={navIsExpanded} />

      <FocusTrap
        paused={!navIsExpanded}
        focusTrapOptions={{ initialFocus: false }}
      >
        <div style={{ display: "contents" }}>
          <MenuButton
            label={indexLabel}
            closeLabel={closeLabel}
            controls={navigationPanelId}
            expanded={navIsExpanded}
            onClick={() => setNavIsExpanded((n) => !n)}
            isHero={isHero}
          />

          <NavigationPanel
            lang={lang}
            onLangChange={onLangChange}
            sections={sections}
            currentSection={currentSection}
            id={navigationPanelId}
            expanded={navIsExpanded}
            onSectionChange={() => setNavIsExpanded(false)}
          />

          {navIsExpanded && (
            <div
              className="header__outside"
              onClick={() => setNavIsExpanded(false)}
            ></div>
          )}
        </div>
      </FocusTrap>
    </header>
  );
}

interface Props extends LangProp {
  onLangChange: LangChangeHandler;
  sections: { [key: string]: Section };
  currentSection: SectionState;
  isPageTop: boolean;
  isHero: boolean;
}

export default Header;
