const brushLine_w95 = "images-react/backgrounds/brush-line_w95.png";
const brushLine_w190 = "images-react/backgrounds/brush-line_w190.png";

function WhatWeDoBackground() {
  return (
    <span className="service-item__background">
      <img
        src={brushLine_w190}
        srcSet={`${brushLine_w95} 95w, ${brushLine_w190} 190w`}
        sizes="95px"
        className="service-item__background-image"
        loading="lazy"
        decoding="async"
        alt=""
      />
    </span>
  );
}

export default WhatWeDoBackground;
