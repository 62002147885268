import "styles/footer/Offices.scss";
import { companyDetails } from "translations";
import { LangProp } from "types";
import OfficeItem from "./OfficeItem";

function Offices({ lang }: LangProp) {
  const { offices, email, phone } = companyDetails[lang];
  const officeItems = offices.map((office, i) => {
    return <OfficeItem key={i} office={office} />;
  });

  return (
    <address className="offices">
      <ul className="offices__list">
        {officeItems}
        <li className="offices__contact">
          <p className="offices__link">
            <a className="underline-hover small" href={"mailto:" + email}>
              {email}
            </a>
          </p>
          <p className="offices__link">
            <a className="underline-hover small" href={"tel:" + phone}>
              {phone}
            </a>
          </p>
        </li>
      </ul>
    </address>
  );
}

export default Offices;
