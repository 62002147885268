import "styles/sections/hero/FigureDetermination.scss";
import {
  CalligraphyDetermination,
  CalligraphyKonbanwa,
  CalligraphyKonnichiwa,
} from "images-react";
import { ClassName } from "types";
import clsx from "clsx";

// dynamic welcome message

const welcomeMessages = {
  morning: {
    text: "Ohayō!",
    image: CalligraphyDetermination,
    hours: [5, 11],
  },
  afternoon: {
    text: "Konnichiwa!",
    image: CalligraphyKonnichiwa,
    hours: [11, 19],
  },
  evening: {
    text: "Konbanwa!",
    image: CalligraphyKonbanwa,
    hours: [19, 5],
  },
};

const currentHour = new Date().getHours();
const currentPartOfTheDay = (() => {
  if (
    currentHour >= welcomeMessages.morning.hours[0] &&
    currentHour < welcomeMessages.morning.hours[1]
  )
    return "morning";
  else if (
    currentHour >= welcomeMessages.afternoon.hours[0] &&
    currentHour < welcomeMessages.afternoon.hours[1]
  )
    return "afternoon";
  else return "evening";
})();

const CalligraphyImage = welcomeMessages[currentPartOfTheDay].image;
const welcomeMessage = welcomeMessages[currentPartOfTheDay].text;

// component

function FigureDetermination({ className }: ClassName) {
  return (
    <figure
      className={clsx("figure-determination", className)}
      role="presentation"
      aria-hidden="true"
    >
      <CalligraphyImage
        className="figure-determination__image"
        role="presentation"
      />
      <figcaption
        className="figure-determination__caption"
        role="presentation"
        lang="jp"
      >
        {welcomeMessage}
      </figcaption>
    </figure>
  );
}

export default FigureDetermination;
