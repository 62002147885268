/* eslint-disable @typescript-eslint/no-unused-vars */
import "styles/sections/team/TeamSection.scss";
import { MutableRefObject, memo, useRef } from "react";
import {
  HeadingDecoration,
  NumberAnimated,
  Section,
  Texture,
} from "components";
import { team } from "translations";
import { GalleryItem, LangProp, Section as SectionType } from "types";
import TeamTexts from "./TeamTexts";
import TeamGallery from "./TeamGallery";
import { useParallax } from "react-scroll-parallax";
import { useOnScreen } from "hooks";

const team_w100 = "images-react/kenji/team_w100.png";
const team_w200 = "images-react/kenji/team_w200.png";
const bestintheworld_w30 = "images-react/calligraphy/bestintheworld_w30.png";
const bestintheworld_w60 = "images-react/calligraphy/bestintheworld_w60.png";
const watercolor2_w982 = "images-react/backgrounds/watercolor-2_w982.png";
const watercolor2_w1964 = "images-react/backgrounds/watercolor-2_w1964.png";

const TeamSection = memo(function TeamSection({ lang, label, refer }: Props) {
  const { headline, texts, galleryLabel, media } = team[lang];
  const { ref: hillsRef } = useParallax<HTMLImageElement>({
    speed: -5,
  });
  const numberRef = useRef<HTMLSpanElement>(null);
  const isVisible = useOnScreen(numberRef, 0.5);

  return (
    <Section className="team" label={label} refer={refer}>
      <div className="team__header">
        <HeadingDecoration
          className="team__decoration"
          src={[
            { size: 100, src: team_w100 },
            { size: 200, src: team_w200 },
          ]}
        />
        <div className="team__headline-container">
          <img
            src={bestintheworld_w60}
            srcSet={`${bestintheworld_w30} 30w, ${bestintheworld_w60} 60w`}
            sizes="30px"
            className="team__kenji"
            loading="lazy"
            decoding="async"
            alt=""
          />
          <div className="team__hills-container">
            <img
              src={watercolor2_w1964}
              srcSet={`${watercolor2_w982} 982w, ${watercolor2_w1964} 1964w`}
              sizes="982px"
              className="team__hills"
              loading="lazy"
              decoding="async"
              alt=""
              ref={hillsRef}
            />
          </div>
          <Texture className="team__texture" />
          <h3 className="team__headline">
            <span className="team__headline-number" ref={numberRef}>
              <NumberAnimated animate={isVisible}>
                {headline[0]!}
              </NumberAnimated>
            </span>
            <br />
            <span className="team__headline-text">{headline[1]}</span>
          </h3>
        </div>
      </div>

      {/* <TeamTexts items={texts} /> */}
      <TeamGallery items={media as GalleryItem[]} label={galleryLabel} />
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default TeamSection;
