import clsx from "clsx";
import "styles/navigation/MenuButton.scss";

function MenuButton({
  onClick,
  label,
  closeLabel,
  controls,
  expanded,
  isHero,
}: Props) {
  const buttonLabel = expanded ? closeLabel : label;

  return (
    <div className="menu-button-container">
      <button
        className={clsx("menu-button", { expanded })}
        aria-controls={controls}
        aria-expanded={expanded}
        onClick={onClick}
      >
        <span
          className={clsx(
            "menu-button__text",
            !isHero && "menu-button__text--dark"
          )}
        >
          {buttonLabel}
        </span>

        <span className="menu-button__icon">
          <span className="menu-button__line-container">
            <span className="menu-button__line menu-button__line--1"></span>
            <span className="menu-button__line menu-button__line--2"></span>
            <span className="menu-button__line menu-button__line--3"></span>
            <span className="menu-button__line menu-button__line--4"></span>
          </span>
        </span>
      </button>
    </div>
  );
}

type Props = {
  onClick: () => void;
  label: string;
  closeLabel: string;
  controls: string;
  expanded: boolean;
  isHero: boolean;
};

export default MenuButton;
