import "styles/sections/what-we-do/WhatWeDoSection.scss";
import { MutableRefObject, memo } from "react";
import { HeadingDecoration, Section } from "components";
import { LangProp, Section as SectionType } from "types";
import { whatWeDo } from "translations";
import WhatWeDoItem from "./WhatWeDoItem";
import {
  OrigamiDarkCrane,
  OrigamiCrown,
  OrigamiDiamond,
  OrigamiSqueezedDiamond,
} from "images-react";

const sword_w64 = "images-react/kenji/sword_w64.png";
const sword_w128 = "images-react/kenji/sword_w128.png";

const origamis = [
  OrigamiDiamond,
  OrigamiSqueezedDiamond,
  OrigamiCrown,
  OrigamiDarkCrane,
];

const WhatWeDoSection = memo(function WhatWeDoSection({
  lang,
  label,
  refer,
}: Props) {
  const { services } = whatWeDo[lang];

  return (
    <Section className="what-we-do" label={label} refer={refer}>
      <div className="what-we-do__container">
        <HeadingDecoration
          className="what-we-do__decoration"
          src={[
            { size: 64, src: sword_w64 },
            { size: 128, src: sword_w128 },
          ]}
        />

        <div className="what-we-do__list-container">
          <ul className="what-we-do__list">
            {services.map((item, i) => (
              <WhatWeDoItem text={item} Svg={origamis[i]} index={i} key={i} />
            ))}
          </ul>
        </div>
      </div>
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default WhatWeDoSection;
