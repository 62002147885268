import "styles/elements/SectionHeadline.scss";
import { Children, ClassName } from "types";
import clsx from "clsx";

function SectionHeadline({ children, className, decoration }: Props) {
  return (
    <h3 className={clsx("section-headline", className)}>
      {decoration ? decoration : ""}
      <span>{children}</span>
    </h3>
  );
}

interface Props extends Children, ClassName {
  decoration?: React.ReactNode;
}

export default SectionHeadline;
