import "styles/navigation/LanguageItem.scss";
import { LangChangeHandler, LangProp } from "types";

function LanguageItem({
  lang,
  fullLang,
  onLangChange,
  current,
  expanded,
}: Props) {
  const handleLangChange = (event: React.MouseEvent) => {
    event.preventDefault();
    onLangChange(lang);
  };

  const LinkAttributes = {
    href: "#" + lang,
    hrefLang: lang,
    lang,
    onClick: handleLangChange,
    tabIndex: expanded ? undefined : -1,
  };

  const langName = <abbr title={fullLang}>{lang}</abbr>;
  const bullet = <span className="language-item__bullet"></span>;

  return current ? (
    <span className="language-item language-item--current">
      {bullet}
      {langName}
    </span>
  ) : (
    <a className="language-item" {...LinkAttributes}>
      {bullet}
      {langName}
    </a>
  );
}

interface Props extends LangProp {
  fullLang: string;
  onLangChange: LangChangeHandler;
  expanded: boolean;
  current?: boolean;
}

export default LanguageItem;
