const brush_w509 = "images-react/backgrounds/brush-small_w509.png";
const brush_w1018 = "images-react/backgrounds/brush-small_w1018.png";

function WhyUsBackground() {
  return (
    <img
      src={brush_w1018}
      srcSet={`${brush_w509} 509w, ${brush_w1018} 1018w`}
      sizes="509px"
      className="why-us__background"
      loading="lazy"
      decoding="async"
      alt=""
    />
  );
}

export default WhyUsBackground;
