import { Texture } from "components";
import BrokenLines from "components/utils/BrokenLines";
import "styles/sections/what-we-do/WhatWeDoItem.scss";
import { pad } from "utils";
import WhatWeDoBackground from "./WhatWeDoBackground";
import { useRef, useState, useEffect } from "react";
import { useOnScreen } from "hooks";
import clsx from "clsx";

interface Props {
  text: string;
  Svg: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  index: number;
}

function WhatWeDoItem({ text, Svg, index }: Props) {
  const itemRef = useRef<HTMLLIElement>(null);
  const isVisible = useOnScreen(itemRef, 0.5);
  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    if (isVisible) setIsViewed(true);
  }, [isVisible]);

  return (
    <li
      className={clsx("service-item", (isVisible || isViewed) && "animate")}
      ref={itemRef}
    >
      {index === 0 && <WhatWeDoBackground />}
      <p className="service-item__container">
        <Texture />
        <span className="service-item__origami-container">
          <Svg
            className="service-item__origami"
            role="presentation"
            aria-hidden="true"
          />
        </span>
        <span className="service-item__number">{pad(index + 1, 2)}</span>
        <span className="service-item__paragraph">
          <BrokenLines>{text}</BrokenLines>
        </span>
      </p>
    </li>
  );
}

export default WhatWeDoItem;
