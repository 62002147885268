import "styles/footer/OfficeItem.scss";
import { Office } from "types";

function OfficeItem({ office }: Props) {
  const { city, addressLine1, addressLine2 } = office;

  return (
    <li className="office">
      <p className="office__city">{city}</p>
      <p className="office__address">
        {addressLine1}
        <br />
        {addressLine2}
        <br />
      </p>
    </li>
  );
}

type Props = {
  office: Office;
};

export default OfficeItem;
