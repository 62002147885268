import "styles/sections/contact/ContactSection.scss";
import { MutableRefObject, memo } from "react";
import { contact } from "translations";
import { LangProp, Section as SectionType } from "types";
import { Section, HeadingDecoration, SectionHeadline } from "components";
import ContactPeople from "./ContactPeople";

const help_w100 = "images-react/kenji/help_w100.png";
const help_w200 = "images-react/kenji/help_w200.png";

const ContactSection = memo(function ContactSection({
  lang,
  label,
  refer,
}: Props) {
  const { headline } = contact[lang];

  return (
    <Section label={label} className="contact" refer={refer}>
      <SectionHeadline
        className="contact__headline"
        decoration={
          <HeadingDecoration
            className="contact__decoration"
            src={[
              { size: 100, src: help_w100 },
              { size: 200, src: help_w200 },
            ]}
          />
        }
      >
        {headline}
      </SectionHeadline>
      <ContactPeople lang={lang} />
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default ContactSection;
