import { NumberAnimated, Texture } from "components";
import { DecorationImage } from "components/elements/HeadingDecoration";
import { useOnScreen } from "hooks";
import { useRef } from "react";
import { useParallax } from "react-scroll-parallax";
import "styles/sections/why-us/WhyUsBenefit.scss";

interface Props {
  heading: string;
  paragraph: string;
  kenji: [DecorationImage, DecorationImage];
  index: number;
}

function WhyUsBenefit({ heading, paragraph, kenji, index }: Props) {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const isVisible = useOnScreen(headingRef, 0.5);
  const { ref: itemRef } = useParallax<HTMLElement>({
    translateY: ["100px", "-10px", "easeOutCubic"],
  });

  return (
    <section className="benefit" ref={itemRef}>
      <Texture />
      <img
        src={kenji[1].src}
        srcSet={`${kenji[0].src} ${kenji[0].size}w, ${kenji[1].src} ${kenji[1].size}w`}
        sizes={`${kenji[0].size}px`}
        className="benefit__kenji"
        loading="lazy"
        decoding="async"
        alt=""
      />
      <h3 className="benefit__heading" ref={headingRef}>
        <NumberAnimated animate={isVisible}>{heading}</NumberAnimated>
        {[1, 3].includes(index) ? (
          <span className="benefit__small">%</span>
        ) : (
          ""
        )}
      </h3>
      <p className="benefit__paragraph">{paragraph}</p>
    </section>
  );
}

export default WhyUsBenefit;
