import { companyDetails } from "translations";
import { LangProp } from "types";

const currentYear = String(new Date().getFullYear());

function YearAndCompanyName({ lang }: LangProp) {
  const { name: companyName } = companyDetails[lang];

  return (
    <>
      <time dateTime={currentYear}>{currentYear}</time> © {companyName}
    </>
  );
}

export default YearAndCompanyName;
