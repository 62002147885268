import "styles/sections/foundation/Foundations.scss";
import { FoundationItemType, LangProp } from "types";
import FoundationGallery from "./FoundationGallery";
import FoundationAchievements from "./FoundationAchievements";
import { useParallax } from "react-scroll-parallax";
import { useScreenResize } from "hooks";
import {
  KarmimyPsiaki,
  Hakersi,
  OrigamiShape,
  OrigamiFlower,
} from "images-react";
import NonBreakableSpaces from "components/utils/NonBreakableSpaces";
import Texture from "components/elements/Texture";
import { DeviceContext } from "components/App";
import { useContext } from "react";

const ink1_w762 = "images-react/backgrounds/ink1_w762.png";
const ink1_w1525 = "images-react/backgrounds/ink1_w1525.png";
const ink2_w697 = "images-react/backgrounds/ink2_w697.png";
const ink2_w1396 = "images-react/backgrounds/ink2_w1396.png";

const logos: { [key: string]: React.ReactNode } = {
  karmimyPsiaki: (
    <KarmimyPsiaki
      className="foundation-item__logo"
      role="presentation"
      aria-hidden="true"
    />
  ),
  hakersi: (
    <Hakersi
      className="foundation-item__logo"
      role="presentation"
      aria-hidden="true"
    />
  ),
};

function Foundations({ items, lang }: FoundationsProps) {
  return (
    <section className="foundations">
      {items.map((item, i) => (
        <FoundationItem item={item} index={i + 1} key={item.name} lang={lang} />
      ))}
    </section>
  );
}

function FoundationItem({ item, index, lang }: FoundationItemProps) {
  const isMobileOrTablet = useContext(DeviceContext);
  const { id, name, logo, description, achievements, galleryLabel } = item;
  const isSmallScreen = useScreenResize(1134);

  const { ref: origamiRef } = useParallax<HTMLDivElement>({
    rotate: [0, -360],
    disabled: isSmallScreen || isMobileOrTablet,
  });
  const { ref: headerRef } = useParallax<HTMLElement>({
    disabled: isSmallScreen || isMobileOrTablet,
    speed: -10,
  });
  const { ref: bgRef } = useParallax<HTMLDivElement>({
    speed: 5,
    disabled: isSmallScreen || isMobileOrTablet,
  });
  const { ref: bgMobileRef } = useParallax<HTMLDivElement>({
    translateY: [
      index === 1 ? "-100px" : "100px",
      index === 1 ? "100px" : "-100px",
    ],
    disabled: !isSmallScreen && !isMobileOrTablet,
  });

  return (
    <article className="foundation-item">
      <div className="foundation-item__container">
        <div
          className={`foundation-item__ink-container foundation-item__ink-container--${index}-mobile`}
        >
          <div ref={bgMobileRef}>
            {index === 1 ? (
              <img
                src={ink1_w1525}
                srcSet={`${ink1_w762} 762w, ${ink1_w1525} 1525w`}
                sizes="762px"
                className="foundation-item__ink foundation-item__ink--1-mobile"
                loading="lazy"
                decoding="async"
                alt=""
              />
            ) : (
              <img
                src={ink2_w1396}
                srcSet={`${ink2_w697} 697w, ${ink2_w1396} 1396w`}
                sizes="697px"
                className="foundation-item__ink foundation-item__ink--2-mobile"
                loading="lazy"
                decoding="async"
                alt=""
              />
            )}
          </div>
        </div>
        <header className="foundation-item__header" ref={headerRef}>
          <div
            className={`foundation-item__ink-container foundation-item__ink-container--${index}`}
          >
            <div ref={bgRef}>
              {index === 1 ? (
                <img
                  src={ink1_w1525}
                  srcSet={`${ink1_w762} 762w, ${ink1_w1525} 1525w`}
                  sizes="762px"
                  className="foundation-item__ink foundation-item__ink--1"
                  loading="lazy"
                  decoding="async"
                  alt=""
                />
              ) : (
                <img
                  src={ink2_w1396}
                  srcSet={`${ink2_w697} 697w, ${ink2_w1396} 1396w`}
                  sizes="697px"
                  className="foundation-item__ink foundation-item__ink--2"
                  loading="lazy"
                  decoding="async"
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="foundation-item__origami-container">
            <div ref={origamiRef}>
              {index === 1 ? (
                <OrigamiFlower
                  className="foundation-item__origami foundation-item__origami--1"
                  role="presentation"
                  aria-hidden="true"
                />
              ) : (
                <OrigamiShape
                  className="foundation-item__origami foundation-item__origami--2"
                  role="presentation"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
          <Texture className="foundation-item__texture" />
          <div className="foundation-item__logo-container">{logos[logo]}</div>
          <div className="foundation-item__header-text">
            <h4 className="foundation-item__heading">{name}</h4>
            <p className="foundation-item__description">
              <NonBreakableSpaces>{description}</NonBreakableSpaces>
            </p>
          </div>
        </header>

        <FoundationAchievements items={achievements} lang={lang} />
      </div>
      <FoundationGallery id={id} label={galleryLabel} />
    </article>
  );
}

interface FoundationsProps extends LangProp {
  items: FoundationItemType[];
}

interface FoundationItemProps extends LangProp {
  item: FoundationItemType;
  index: number;
}

export default Foundations;
