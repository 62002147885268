import "styles/sections/hero/ScrollDown.scss";
import { useState, useEffect, useRef, useContext } from "react";
import { ArrowDown } from "images-react";
import { useLenis } from "@studio-freight/react-lenis";
import { wait } from "utils";
import clsx from "clsx";
import { DeviceContext } from "components/App";

function ScrollDown({ scrollLabel, nextSection }: Props) {
  const isMobileOrTablet = useContext(DeviceContext);
  const lenis = useLenis();

  const scrollLabelMobile = scrollLabel.split(" ")[0];

  type AnimationStages =
    | "to-middle"
    | "to-bottom"
    | "to-top"
    | "to-bottom reset"
    | "to-middle reset";
  const [isAnimating, setIsAnimating] = useState(true);
  const [initialAnimation, setInitialAnimation] = useState(true);
  const [animationStage, setAnimationStage] =
    useState<AnimationStages>("to-middle");
  const animationIntervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const animationSequence = async () => {
      await wait(1);
      setAnimationStage("to-bottom");
      await wait(0.3);
      setAnimationStage("to-bottom reset");
      await wait(0.2);
      setAnimationStage("to-top");
      await wait(0.1);
      setAnimationStage("to-middle");
      await wait(0.3);
      setAnimationStage("to-middle reset");
    };

    if (isAnimating) {
      if (initialAnimation) {
        animationSequence();
        setInitialAnimation(false);
      }

      animationIntervalRef.current = setInterval(() => {
        animationSequence();
      }, 4000);
    } else {
      if (animationIntervalRef.current !== null) {
        clearInterval(animationIntervalRef.current);
        animationIntervalRef.current = null;
        setAnimationStage("to-middle");
      }
    }

    return () => {
      if (animationIntervalRef.current !== null) {
        clearInterval(animationIntervalRef.current);
        animationIntervalRef.current = null;
      }
    };
  }, [isAnimating, initialAnimation]);

  const handleLinkClick = () => {
    if (isMobileOrTablet) return;
    lenis.scrollTo(nextSection);
  };

  return (
    <a
      className="scroll-down-link"
      href={nextSection}
      onClick={handleLinkClick}
      onMouseEnter={() => setIsAnimating(false)}
      onMouseLeave={() => setIsAnimating(true)}
    >
      <div className={clsx("scroll-down", animationStage)}>
        <div className="scroll-down__icon-container scroll-down__icon-container--default">
          <ArrowDown
            className="scroll-down__icon"
            role="presentation"
            aria-hidden="true"
          />
        </div>
        <div className="scroll-down__icon-container scroll-down__icon-container--hover">
          <ArrowDown
            className="scroll-down__icon"
            role="presentation"
            aria-hidden="true"
          />
        </div>
      </div>
      <span className="scroll-down__text">
        {isMobileOrTablet ? scrollLabelMobile : scrollLabel}
      </span>
    </a>
  );
}

interface Props {
  scrollLabel: string;
  nextSection: string;
}

export default ScrollDown;
