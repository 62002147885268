import "styles/utils/NewLine.scss";
import { Children } from "types";
import NonBreakableSpaces from "./NonBreakableSpaces";

function NewLine({ children }: Children) {
  return (
    <span className="new-line">
      <NonBreakableSpaces>{String(children)}</NonBreakableSpaces>
    </span>
  );
}

export default NewLine;
