import { useState, useEffect } from "react";

export default function useScreenResize(width: number) {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    setIsScreenSmall(window.screen.availWidth < width);

    const setResizeTimeout = () =>
      setTimeout(() => {
        setIsScreenSmall(window.screen.availWidth < width);
      }, 200);
    let resizeTimeout = setResizeTimeout();

    window.addEventListener("resize", () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setResizeTimeout();
    });
  }, [width]);

  return isScreenSmall;
}
