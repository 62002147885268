import StrongText from "./StrongText";

function Paragraphs({ children: text }: Props) {
  const paragraphs = text.split("\n").map((paragraph, i) => (
    <p key={i}>
      <StrongText>{paragraph}</StrongText>
    </p>
  ));
  return <>{paragraphs}</>;
}

type Props = {
  children: string;
};

export default Paragraphs;
