import clsx from "clsx";
import { general, socialMedia } from "translations";
import { LangProp } from "types";
import { ArrowTopRight } from "images-react";

function SocialMedia({ lang, noHeading, expanded, classModifier }: Props) {
  const socialMediaId = classModifier + "social-media-heading";
  const classBase = classModifier + "-social-media";
  const { socialMediaLabel } = general[lang];
  const hasTab = expanded === undefined ? true : expanded;

  const socialMediaItems = socialMedia[lang].map((item) => (
    <li className={classBase + "__item"} key={item.name}>
      <a
        className={classBase + "__link"}
        href={item.url}
        hrefLang={item.lang}
        lang={item.lang}
        target="_blank"
        rel="noopener noreferrer"
        tabIndex={hasTab ? undefined : -1}
      >
        <ArrowTopRight
          className={classBase + "__arrow"}
          role="presentation"
          aria-hidden="true"
        />
        <abbr className={classBase + "__text"} title={item.name}>
          {item.shortName}
        </abbr>
      </a>
    </li>
  ));

  return (
    <section className={classBase} aria-labelledby={socialMediaId}>
      <h2
        className={clsx(
          classBase + "__heading",
          noHeading && "visually-hidden"
        )}
        id={socialMediaId}
      >
        {socialMediaLabel}
      </h2>
      <ul className={classBase + "__list"}>{socialMediaItems}</ul>
    </section>
  );
}

interface Props extends LangProp {
  classModifier: string;
  expanded?: boolean;
  noHeading?: boolean;
}

export default SocialMedia;
