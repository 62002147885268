import "styles/layout/Section.scss";
import clsx from "clsx";
import SectionHeading from "components/elements/SectionHeading";
import { MutableRefObject } from "react";
import { Children, Section as SectionType } from "types";
import { slugify } from "utils";

function Section({ label, className, children, refer, heart }: Props) {
  const sectionSlug = slugify(label.short);
  const headingId = sectionSlug + "-heading";

  return (
    <section
      className={clsx("section", className)}
      id={sectionSlug}
      aria-labelledby={headingId}
    >
      <SectionHeading id={headingId} refer={refer} heart={heart}>
        {label.long}
      </SectionHeading>
      {children}
    </section>
  );
}

interface Props extends Children {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
  className?: string;
  heart?: boolean;
}

export default Section;
