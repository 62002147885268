import "styles/sections/foundation/FoundationSection.scss";
import { MutableRefObject, memo } from "react";
import {
  BrokenLines,
  HeadingDecoration,
  Paragraphs,
  Section,
  SectionHeadline,
  Texture,
} from "components";
import { FoundationData, LangProp, Section as SectionType } from "types";
import { foundation } from "translations";
import Foundations from "./Foundations";
import { SarigatoFoundationLogo } from "images-react";
import { useParallax } from "react-scroll-parallax";

const dream_w80 = "images-react/kenji/dream_w80.png";
const dream_w160 = "images-react/kenji/dream_w160.png";
const cafBadge_w150 = "images-react/foundation/caf-badge_w150.png";
const cafBadge_w300 = "images-react/foundation/caf-badge_w300.png";

const FoundationSection = memo(function FoundationSection({
  lang,
  label,
  refer,
}: Props) {
  const { headline, foundations, text, foundationLogoAlt, cafBadgeAlt } =
    foundation[lang] as FoundationData;

  const { ref: badgeRef } = useParallax<HTMLImageElement>({
    scaleX: [0, 1, "easeOutExpo"],
    scaleY: [0, 1, "easeOutExpo"],
  });

  return (
    <Section className="foundation" label={label} refer={refer} heart>
      <SectionHeadline
        className="foundation__headline"
        decoration={
          <HeadingDecoration
            className="foundation__decoration"
            src={[
              { size: 80, src: dream_w80 },
              { size: 160, src: dream_w160 },
            ]}
          />
        }
      >
        <BrokenLines>{headline}</BrokenLines>
      </SectionHeadline>

      <div className="foundation__about foundation-about">
        <div className="foundation-about__container foundation-about__container--text">
          <Paragraphs>{text}</Paragraphs>
        </div>
        <div className="foundation-about__container foundation-about__container--logo">
          <Texture className="foundation-about__texture" />
          <div className="foundation-about__badge-container">
            <img
              src={cafBadge_w300}
              srcSet={`${cafBadge_w150} 150w, ${cafBadge_w300} 300w`}
              sizes="150px"
              className="foundation-about__badge"
              loading="lazy"
              decoding="async"
              alt={cafBadgeAlt}
              ref={badgeRef}
            />
          </div>
          <SarigatoFoundationLogo
            className="foundation-about__logo"
            role="img"
            title={foundationLogoAlt}
          />
        </div>
      </div>

      <Foundations items={foundations} lang={lang} />
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default FoundationSection;
