import "styles/navigation/NavigationPanel.scss";
import "styles/navigation/NavSocialMedia.scss";
import clsx from "clsx";
import { LangChangeHandler, LangProp, Section, SectionState } from "types";
import { LanguageNav, IndexNav } from "components/navigation";
import { SocialMedia, YearAndCompanyName } from "components";
import { general } from "translations";
import { SarigatoGroupLogo } from "images-react";

function NavigationPanel({
  lang,
  onLangChange,
  sections,
  currentSection,
  id,
  expanded,
  onSectionChange,
}: Props) {
  const { navigationPanelLabel, indexLabel } = general[lang];

  return (
    <nav
      id={id}
      aria-label={navigationPanelLabel}
      className={clsx("navigation-panel", { expanded })}
      data-lenis-prevent
    >
      <LanguageNav
        lang={lang}
        onLangChange={onLangChange}
        expanded={expanded}
      />

      <IndexNav
        label={indexLabel}
        sections={sections}
        currentSection={currentSection}
        expanded={expanded}
        onSectionChange={onSectionChange}
      />

      <SocialMedia lang={lang} expanded={expanded} classModifier="nav" />

      <p className="navigation-panel__copyright">
        <span className="navigation-panel__text">
          <YearAndCompanyName lang={lang} />
        </span>
        <SarigatoGroupLogo
          className="navigation-panel__logo"
          role="presentation"
          aria-hidden="true"
        />
      </p>
    </nav>
  );
}

interface Props extends LangProp {
  onLangChange: LangChangeHandler;
  sections: { [key: string]: Section };
  currentSection: SectionState;
  id: string;
  expanded: boolean;
  onSectionChange: () => void;
}

export default NavigationPanel;
