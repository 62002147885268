import "styles/sections/clients/ClientList.scss";

import { Client } from "types";
import { clientLogo } from "images-react";

function ClientList({ items }: ListProps) {
  return (
    <div className="client-list-container">
      <ClientItems items={items} />
      <ClientItems items={items} isCopy />
    </div>
  );
}

function ClientItems({ items, isCopy }: ItemsProps) {
  const computedStyles = {
    flexBasis: `${(items.length * 100) / (items.length < 7 ? 6 : 7)}%`,
    animationDuration: `${items.length * 3}s`,
  };

  return (
    <ul className="client-list" style={computedStyles} aria-hidden={isCopy}>
      {items.map(({ src, name }) => (
        <li className="client-item" key={name}>
          <img
            className="client-item__image"
            src={clientLogo[src]}
            alt={name}
          />
        </li>
      ))}
    </ul>
  );
}

interface ListProps {
  items: Client[];
}

interface ItemsProps extends ListProps {
  isCopy?: boolean;
}

export default ClientList;
