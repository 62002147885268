import { useParallax } from "react-scroll-parallax";
import { useContext } from "react";
import { DeviceContext } from "components/App";
import type { AwardImage } from "./CredibilityAwards";

interface Props {
  src: AwardImage[];
  alt: string;
  i: number;
}

function CredibilityAwardImage({ src, alt, i }: Props) {
  const isMobileOrTablet = useContext(DeviceContext);

  const { ref: imageRef } = useParallax<HTMLImageElement>({
    scaleX: [0, 1],
    scaleY: [0, 1],
    rootMargin: { top: 40 - i * 40, right: 0, bottom: 0, left: 0 },
    easing: "easeOutExpo",
    disabled: isMobileOrTablet,
  });

  return (
    <div className="credibility-awards__image-container" key={i}>
      <img
        src={src[2].png}
        srcSet={`${src[0].png} ${src[0].size}w, ${src[1].png} ${src[1].size}w, ${src[2].png} ${src[2].size}w`}
        sizes={`${src[0].size}px`}
        className="credibility-awards__image"
        ref={imageRef}
        alt={alt}
      />
    </div>
  );
}

export default CredibilityAwardImage;
