import "styles/sections/clients/ClientsSection.scss";
import { MutableRefObject, memo } from "react";
import {
  BrokenLines,
  HeadingDecoration,
  Section,
  SectionHeadline,
} from "components";
import { LangProp, Section as SectionType } from "types";
import { clients } from "translations";
import ClientList from "./ClientList";

const journey_w80 = "images-react/kenji/journey_w80.png";
const journey_w160 = "images-react/kenji/journey_w160.png";

const ClientsSection = memo(function ClientsSection({
  lang,
  label,
  refer,
}: Props) {
  const { headline, lists } = clients[lang];

  return (
    <Section className="clients" label={label} refer={refer}>
      <SectionHeadline
        className="clients__headline"
        decoration={
          <HeadingDecoration
            className="clients__decoration"
            src={[
              { size: 80, src: journey_w80 },
              { size: 160, src: journey_w160 },
            ]}
          />
        }
      >
        <BrokenLines>{headline}</BrokenLines>
      </SectionHeadline>
      {lists.map((list, i) => (
        <ClientList items={list} key={i} />
      ))}
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default ClientsSection;
