import "styles/sections/why-us/WhyUsSection.scss";
import { MutableRefObject, memo } from "react";
import { HeadingDecoration, Section } from "components";
import { LangProp, Section as SectionType } from "types";
import { whyUs } from "translations";
import WhyUsBenefit from "./WhyUsBenefit";
import { DecorationImage } from "components/elements/HeadingDecoration";
import WhyUsBackground from "./WhyUsBackground";

const now_w100 = "images-react/kenji/now_w100.png";
const now_w200 = "images-react/kenji/now_w200.png";

const ambitions_w30 = "images-react/calligraphy/ambitions_w30.png";
const ambitions_w60 = "images-react/calligraphy/ambitions_w60.png";
const instructor_w30 = "images-react/calligraphy/instructor_w30.png";
const instructor_w60 = "images-react/calligraphy/instructor_w60.png";
const champions_w30 = "images-react/calligraphy/champions_w30.png";
const champions_w60 = "images-react/calligraphy/champions_w60.png";
const bestintheworld_w30 = "images-react/calligraphy/bestintheworld_w30.png";
const bestintheworld_w60 = "images-react/calligraphy/bestintheworld_w60.png";

const kenjis: [DecorationImage, DecorationImage][] = [
  [
    { size: 30, src: ambitions_w30 },
    { size: 60, src: ambitions_w60 },
  ],
  [
    { size: 30, src: champions_w30 },
    { size: 60, src: champions_w60 },
  ],
  [
    { size: 30, src: instructor_w30 },
    { size: 60, src: instructor_w60 },
  ],
  [
    { size: 30, src: bestintheworld_w30 },
    { size: 60, src: bestintheworld_w60 },
  ],
];

const WhyUsSection = memo(function WhyUsSection({ lang, label, refer }: Props) {
  const { benefits } = whyUs[lang];

  return (
    <Section className="why-us" label={label} refer={refer}>
      <div className="why-us__container">
        <HeadingDecoration
          className="why-us__decoration"
          src={[
            { size: 100, src: now_w100 },
            { size: 200, src: now_w200 },
          ]}
        />

        <div className="why-us__benefits">
          <WhyUsBackground />
          <WhyUsBackground />
          <WhyUsBackground />
          {benefits.map((item, i) => (
            <WhyUsBenefit
              heading={item.heading}
              paragraph={item.paragraph}
              kenji={kenjis[i]}
              index={i}
              key={i}
            />
          ))}
        </div>
      </div>
    </Section>
  );
});

interface Props extends LangProp {
  label: SectionType;
  refer: MutableRefObject<HTMLHeadingElement | null>;
}

export default WhyUsSection;
