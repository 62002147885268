import "styles/navigation/IndexNav.scss";
import { MouseEvent, useContext } from "react";
import { ArrowRight } from "images-react";
import { Section, SectionState } from "types";
import { getAnchor } from "utils";
import clsx from "clsx";
import { useLenis } from "@studio-freight/react-lenis";
import { DeviceContext } from "components/App";

function IndexNav({
  label,
  sections,
  currentSection,
  expanded,
  onSectionChange,
}: Props) {
  const lenis = useLenis();
  const isMobileOrTablet = useContext(DeviceContext);

  const handleSectionClick = (event: MouseEvent<HTMLAnchorElement>) => {
    onSectionChange();
    if (isMobileOrTablet) return;
    const target = event.target as HTMLSpanElement;
    const anchor = target.closest("a") as HTMLAnchorElement;
    const href = "#" + anchor.href.split("#")[1];
    lenis.scrollTo(href, { duration: 3 });
  };

  const sectionLinks = Object.entries(sections).map(([key, section]) => {
    const anchor = getAnchor(section.short);
    const isCurrentSection = key === currentSection;

    // hide reviews section link
    if (key === "reviews") return null;

    return (
      <li className="main-navigation__item" key={section.short}>
        <a
          className={clsx(
            "main-navigation__link",
            isCurrentSection && "main-navigation__link--current"
          )}
          href={anchor}
          tabIndex={expanded ? undefined : -1}
          onClick={handleSectionClick}
        >
          <span className="main-navigation__bullet"></span>
          <span className="main-navigation__section-arrow">
            <span className="main-navigation__section-name">
              {section.short}
            </span>
            <ArrowRight
              className="main-navigation__arrow"
              role="presentation"
              aria-hidden="true"
            />
          </span>
        </a>
      </li>
    );
  });

  sectionLinks.shift();

  return (
    <nav className="main-navigation" aria-labelledby="main-navigation-heading">
      <h2 className="main-navigation__heading" id="main-navigation-heading">
        {label}
      </h2>
      <ul className="main-navigation__list">{sectionLinks}</ul>
    </nav>
  );
}

interface Props {
  label: string;
  sections: { [key: string]: Section };
  currentSection: SectionState;
  expanded: boolean;
  onSectionChange: () => void;
}

export default IndexNav;
