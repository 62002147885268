function isOverflown(element: HTMLElement | null, horizontal = false) {
  if (!element) return false;
  if (horizontal) return element.scrollWidth >= element.clientWidth;
  return (
    element.scrollHeight >= element.clientHeight ||
    element.scrollWidth >= element.clientWidth
  );
}

export default isOverflown;
