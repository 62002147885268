import "styles/sections/foundation/FoundationGallery.scss";
import clsx from "clsx";
import { useRef } from "react";
import { slugify } from "utils";
import { useOnOverflow } from "hooks";

// Images Karmimy Psiaki
const kp01 = "images-react/foundation/karmimy-psiaki/kp (1).jpg";
const kp02 = "images-react/foundation/karmimy-psiaki/kp (2).jpg";
const kp03 = "images-react/foundation/karmimy-psiaki/kp (3).jpg";
const kp04 = "images-react/foundation/karmimy-psiaki/kp (4).jpg";
const kp05 = "images-react/foundation/karmimy-psiaki/kp (5).jpg";
const kp06 = "images-react/foundation/karmimy-psiaki/kp (6).jpg";
const kp07 = "images-react/foundation/karmimy-psiaki/kp (7).jpg";
const kp08 = "images-react/foundation/karmimy-psiaki/kp (8).jpg";
const kp09 = "images-react/foundation/karmimy-psiaki/kp (9).jpg";
const kp10 = "images-react/foundation/karmimy-psiaki/kp (10).jpg";
const kp11 = "images-react/foundation/karmimy-psiaki/kp (11).jpg";
const kp12 = "images-react/foundation/karmimy-psiaki/kp (12).jpg";
const kp13 = "images-react/foundation/karmimy-psiaki/kp (13).jpg";
const kp14 = "images-react/foundation/karmimy-psiaki/kp (14).jpg";
const kp15 = "images-react/foundation/karmimy-psiaki/kp (15).jpg";
const kp16 = "images-react/foundation/karmimy-psiaki/kp (16).jpg";
const kp17 = "images-react/foundation/karmimy-psiaki/kp (17).jpg";
const kp18 = "images-react/foundation/karmimy-psiaki/kp (18).jpg";
const kp19 = "images-react/foundation/karmimy-psiaki/kp (19).jpg";
const kp20 = "images-react/foundation/karmimy-psiaki/kp (20).jpg";
const kp21 = "images-react/foundation/karmimy-psiaki/kp (21).jpg";
const kp22 = "images-react/foundation/karmimy-psiaki/kp (22).jpg";
const kp23 = "images-react/foundation/karmimy-psiaki/kp (23).jpg";

// Images Hakersi
const ha01 = "images-react/foundation/hakersi/ha01.jpg";
const ha02 = "images-react/foundation/hakersi/ha02.jpg";
const ha03 = "images-react/foundation/hakersi/ha03.jpg";
const ha04 = "images-react/foundation/hakersi/ha04.jpg";
const ha05 = "images-react/foundation/hakersi/ha05.jpg";
const ha06 = "images-react/foundation/hakersi/ha06.jpg";
const ha07 = "images-react/foundation/hakersi/ha07.jpg";
const ha08 = "images-react/foundation/hakersi/ha08.jpg";
const ha09 = "images-react/foundation/hakersi/ha09.jpg";
const ha10 = "images-react/foundation/hakersi/ha10.jpg";
const ha11 = "images-react/foundation/hakersi/ha11.jpg";
const ha12 = "images-react/foundation/hakersi/ha12.jpg";
const ha13 = "images-react/foundation/hakersi/ha13.jpg";
const ha14 = "images-react/foundation/hakersi/ha14.jpg";
const ha15 = "images-react/foundation/hakersi/ha15.jpg";
const ha16 = "images-react/foundation/hakersi/ha16.jpg";
const ha17 = "images-react/foundation/hakersi/ha17.jpg";
const ha18 = "images-react/foundation/hakersi/ha18.jpg";
const ha19 = "images-react/foundation/hakersi/ha19.jpg";
const ha20 = "images-react/foundation/hakersi/ha20.jpg";

const imagesKp = [
  kp01,
  kp02,
  kp03,
  kp04,
  kp05,
  kp06,
  kp07,
  kp08,
  kp09,
  kp10,
  kp11,
  kp12,
  kp13,
  kp14,
  kp15,
  kp16,
  kp17,
  kp18,
  kp19,
  kp20,
  kp21,
  kp22,
  kp23,
];

const imagesHa = [
  ha01,
  ha02,
  ha03,
  ha04,
  ha05,
  ha06,
  ha07,
  ha08,
  ha09,
  ha10,
  ha11,
  ha12,
  ha13,
  ha14,
  ha15,
  ha16,
  ha17,
  ha18,
  ha19,
  ha20,
];

function FoundationGallery({ id, label }: FoundationGalleryProps) {
  const sectionId = `caption-${slugify(label)}`;

  // check if all images fit inside the container
  const containerRef = useRef(null);
  const containerIsOverflown = useOnOverflow(containerRef, [], 200, true);

  return (
    <figure
      className={clsx(
        "foundation-gallery",
        containerIsOverflown && "foundation-gallery--overflown"
      )}
      role="group"
      aria-labelledby={sectionId}
      ref={containerRef}
    >
      <figcaption className="visually-hidden" id={sectionId}>
        {label}
      </figcaption>

      <FoundationGalleryList id={id} />
      {containerIsOverflown && <FoundationGalleryList id={id} />}
    </figure>
  );
}

function FoundationGalleryList({ id }: FoundationGalleryListProps) {
  const items = id === "kp" ? imagesKp : imagesHa;

  return (
    <div className={clsx("foundation-gallery__container", id)}>
      {items.map((src, i) => (
        <div className="foundation-gallery__item" key={i}>
          <img className="foundation-gallery__image" src={src} alt="" />
        </div>
      ))}
    </div>
  );
}

type FoundationGalleryProps = {
  id: string;
  label: string;
};

type FoundationGalleryListProps = {
  id: string;
};

export default FoundationGallery;
