// media houses
import dentsu from "images-react/logos/domy mediowe/dentsu.png";
import havas from "images-react/logos/domy mediowe/havas.png";
import initiative from "images-react/logos/domy mediowe/initiative.png";
import k2 from "images-react/logos/domy mediowe/k2.png";
import mediacom from "images-react/logos/domy mediowe/mediacom.png";
import mediaDirection from "images-react/logos/domy mediowe/media direction.png";
import mediaRepublic from "images-react/logos/domy mediowe/media republic.png";
import mediaplus from "images-react/logos/domy mediowe/madiaplus.png";
import mindshare from "images-react/logos/domy mediowe/mindshare.png";
import mullenlowe from "images-react/logos/domy mediowe/mullenlowe.png";
import omd from "images-react/logos/domy mediowe/omd.png";
import phd from "images-react/logos/domy mediowe/phd.png";
import reprise from "images-react/logos/domy mediowe/reprise.png";
import rlMedia from "images-react/logos/domy mediowe/rl media.png";
import salesandmore from "images-react/logos/domy mediowe/salesandmore.png";
import salestube from "images-react/logos/domy mediowe/salestube.png";
import starcom from "images-react/logos/domy mediowe/starcom.png";
import um from "images-react/logos/domy mediowe/um.png";
import valuemedia from "images-react/logos/domy mediowe/valuemedia.png";
import wavemaker from "images-react/logos/domy mediowe/wavemaker.png";
import zenith from "images-react/logos/domy mediowe/zenith.png";

import audi from "images-react/logos/motoryzacja/audi.png";
import bmw from "images-react/logos/motoryzacja/bmw.png";
import dacia from "images-react/logos/motoryzacja/dacia.png";
import ford from "images-react/logos/motoryzacja/ford.png";
import honda from "images-react/logos/motoryzacja/honda.png";
import hyundai from "images-react/logos/motoryzacja/hyundai.png";
import kia from "images-react/logos/motoryzacja/kia.png";
import landrover from "images-react/logos/motoryzacja/landrover.png";
import lexus from "images-react/logos/motoryzacja/lexus.png";
import mazda from "images-react/logos/motoryzacja/mazda.png";
import mercedes from "images-react/logos/motoryzacja/mercedes.png";
import mini from "images-react/logos/motoryzacja/mini.png";
import nissan from "images-react/logos/motoryzacja/nissan.png";
import peugeot from "images-react/logos/motoryzacja/peugeot.png";
import porsche from "images-react/logos/motoryzacja/porsche.png";
import renault from "images-react/logos/motoryzacja/renault.png";
import seat from "images-react/logos/motoryzacja/seat.png";
import skoda from "images-react/logos/motoryzacja/skoda.png";
import toyota from "images-react/logos/motoryzacja/toyota.png";
import volvo from "images-react/logos/motoryzacja/volvo.png";
import vw from "images-react/logos/motoryzacja/vw.png";

// fmcg
import almette from "images-react/logos/fmcg/almette.png";
import alpro from "images-react/logos/fmcg/alpro.png";
import carlsberg from "images-react/logos/fmcg/carlsberg.png";
import cocaCola from "images-react/logos/fmcg/cocaCola.png";
import danone from "images-react/logos/fmcg/danone.png";
import ferrero from "images-react/logos/fmcg/ferrero.png";
import grupazywiec from "images-react/logos/fmcg/grupazywiec.png";
import heinz from "images-react/logos/fmcg/heinz.png";
import hochland from "images-react/logos/fmcg/hochland.png";
import jacobs from "images-react/logos/fmcg/jacobs.png";
import jemyjemy from "images-react/logos/fmcg/jemyjemy.png";
import kfc from "images-react/logos/fmcg/kfc.png";
import kruszwica from "images-react/logos/fmcg/kruszwica.png";
import lipton from "images-react/logos/fmcg/lipton.png";
import mcdonald from "images-react/logos/fmcg/mcdonald.png";
import monini from "images-react/logos/fmcg/monini.png";
import mosso from "images-react/logos/fmcg/mosso.png";
import pepsi from "images-react/logos/fmcg/pepsi.png";
import snickers from "images-react/logos/fmcg/snickers.png";
import sokolow from "images-react/logos/fmcg/sokolow.png";
import studentska from "images-react/logos/fmcg/studentska.png";
import subway from "images-react/logos/fmcg/subway.png";
import tchibo from "images-react/logos/fmcg/tchibo.png";
import winiary from "images-react/logos/fmcg/winiary.png";

// entertainment
import cenega from "images-react/logos/rozrywka/cenega.png";
import disney from "images-react/logos/rozrywka/disney.png";
import hbo from "images-react/logos/rozrywka/hbo.png";
import lego from "images-react/logos/rozrywka/lego.png";
import netflix from "images-react/logos/rozrywka/netflix.png";
import playstation from "images-react/logos/rozrywka/playstation.png";
import prime from "images-react/logos/rozrywka/prime.png";
import tvn from "images-react/logos/rozrywka/tvn.png";
import ubisoft from "images-react/logos/rozrywka/ubisoft.png";

// sieci handlowe
import kaufland from "images-react/logos/sieci handlowe/kaufland.png";
import kontigo from "images-react/logos/sieci handlowe/kontigo.png";
import pepco from "images-react/logos/sieci handlowe/pepco.png";
import rossmann from "images-react/logos/sieci handlowe/rossmann.png";
import sephora from "images-react/logos/sieci handlowe/sephora.png";
import zabka from "images-react/logos/sieci handlowe/zabka.png";

// uroda
import avon from "images-react/logos/uroda/avon.png";
import bourjois from "images-react/logos/uroda/bourjois.png";
import ck from "images-react/logos/uroda/ck.png";
import clinique from "images-react/logos/uroda/clinique.png";
import dove from "images-react/logos/uroda/dove.png";
import drirena from "images-react/logos/uroda/drirena.png";
import estee from "images-react/logos/uroda/estee.png";
import garnier from "images-react/logos/uroda/garnier.png";
import jomalone from "images-react/logos/uroda/jomalone.png";
import laroche from "images-react/logos/uroda/laroche.png";
import lepetitm from "images-react/logos/uroda/lepetitm.png";
import loreal from "images-react/logos/uroda/loreal.png";
import maxfactor from "images-react/logos/uroda/maxfactor.png";
import nivea from "images-react/logos/uroda/nivea.png";
import pharmaceris from "images-react/logos/uroda/pharmaceris.png";
import phlov from "images-react/logos/uroda/phlov.png";
import rexona from "images-react/logos/uroda/rexona.png";
import rimmel from "images-react/logos/uroda/rimmel.png";
import sallyhansen from "images-react/logos/uroda/sallyhansen.png";
import tolpa from "images-react/logos/uroda/tolpa.png";
import vichy from "images-react/logos/uroda/vichy.png";
import wella from "images-react/logos/uroda/wella.png";

const clientLogo: { [key: string]: string } = {
  dentsu,
  havas,
  initiative,
  k2,
  mediaplus,
  mediaDirection,
  mediaRepublic,
  mediacom,
  mindshare,
  mullenlowe,
  omd,
  phd,
  reprise,
  rlMedia,
  salesandmore,
  salestube,
  starcom,
  um,
  valuemedia,
  wavemaker,
  zenith,
  audi,
  bmw,
  mini,
  kruszwica,
  mosso,
  honda,
  porsche,
  renault,
  vw,
  landrover,
  lexus,
  dacia,
  mazda,
  seat,
  ford,
  mercedes,
  skoda,
  toyota,
  hyundai,
  nissan,
  volvo,
  kia,
  peugeot,
  pepsi,
  cocaCola,
  heinz,
  danone,
  ferrero,
  hochland,
  zabka,
  sokolow,
  almette,
  alpro,
  jacobs,
  carlsberg,
  jemyjemy,
  snickers,
  kfc,
  grupazywiec,
  mcdonald,
  tchibo,
  monini,
  winiary,
  lipton,
  subway,
  studentska,
  garnier,
  phlov,
  cenega,
  lego,
  prime,
  disney,
  netflix,
  tvn,
  hbo,
  playstation,
  ubisoft,
  kaufland,
  pepco,
  sephora,
  kontigo,
  rossmann,
  avon,
  jomalone,
  rexona,
  bourjois,
  laroche,
  rimmel,
  ck,
  lepetitm,
  sallyhansen,
  clinique,
  loreal,
  tolpa,
  dove,
  maxfactor,
  vichy,
  drirena,
  nivea,
  wella,
  estee,
  pharmaceris,
};

export default clientLogo;
