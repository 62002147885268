import { useState, useEffect, MutableRefObject } from "react";
import { isOverflown } from "utils";

function useOnOverflow(
  containerRef: MutableRefObject<HTMLElement | null>,
  dependencies: any[] = [],
  throttle: number = 200,
  horizontal = false
) {
  const [isContainerOverflown, setIsContainerOverflown] = useState(
    isOverflown(containerRef.current, horizontal)
  );

  useEffect(() => {
    setIsContainerOverflown(isOverflown(containerRef.current, horizontal));
    // throttle the function to avoid performance issues
    const setResizeTimeout = () =>
      setTimeout(() => {
        setIsContainerOverflown(isOverflown(containerRef.current, horizontal));
      }, throttle);
    let resizeTimeout = setResizeTimeout();

    window.addEventListener("resize", () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setResizeTimeout();
    });
  }, [containerRef, throttle, ...dependencies]); // eslint-disable-line

  return isContainerOverflown;
}

export default useOnOverflow;
