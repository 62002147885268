import "styles/elements/SectionHeading.scss";
import { MutableRefObject } from "react";
import { Children } from "types";
import { Heart } from "images-react";

function SectionHeading({ children, id, refer, heart }: Props) {
  return (
    <h2 className="section-heading" id={id} ref={refer} tabIndex={0}>
      {heart ? (
        <Heart
          className="section-heading__heart"
          role="presentation"
          aria-hidden="true"
        />
      ) : (
        <span className="section-heading__icon"></span>
      )}
      {children}
    </h2>
  );
}

interface Props extends Children {
  id: string;
  refer: MutableRefObject<HTMLHeadingElement | null>;
  heart?: boolean;
}

export default SectionHeading;
