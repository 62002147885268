import "styles/sections/hero/HeroContainer.scss";
import { Children } from "types";
import { MutableRefObject, useEffect } from "react";
import Texture from "components/elements/Texture";
import Parallax from "parallax-js";
import { useParallax } from "react-scroll-parallax";

const strength_w60 = "images-react/hero/inner-strength_w60.png";
const strength_w120 = "images-react/hero/inner-strength_w120.png";
const strength_w180 = "images-react/hero/inner-strength_w180.png";

const geisha_w460png = "images-react/hero/geisha_w460.png";
const geisha_w920png = "images-react/hero/geisha_w920.png";
const geisha_w460webp = "images-react/hero/geisha_w460.webp";
const geisha_w920webp = "images-react/hero/geisha_w920.webp";

const stone_w1168 = "images-react/hero/stone_w1168.png";
const stone_w584 = "images-react/hero/stone_w584.png";

interface Props extends Children {
  heroRef: MutableRefObject<HTMLDivElement | null>;
  isMobile: boolean;
}

function HeroContainer({ children, heroRef, isMobile }: Props) {
  useEffect(() => {
    // ignore best practices to work with vanilla js library
    const circlesScene =
      document.querySelector<HTMLDivElement>("#circles-scene");
    const sunScene = document.querySelector<HTMLDivElement>("#sun-scene");
    const stoneScene = document.querySelector<HTMLDivElement>("#stone-scene");
    const geishaScene = document.querySelector<HTMLDivElement>("#geisha-scene");
    const strengthScene =
      document.querySelector<HTMLDivElement>("#strength-scene");
    const circlesParallax = circlesScene // eslint-disable-line
      ? new Parallax(circlesScene)
      : undefined;
    const sunParallax = sunScene ? new Parallax(sunScene) : undefined; // eslint-disable-line
    const stoneParallax = stoneScene ? new Parallax(stoneScene) : undefined; // eslint-disable-line
    const geishaParallax = geishaScene ? new Parallax(geishaScene) : undefined; // eslint-disable-line
    const strengthParallax = strengthScene // eslint-disable-line
      ? new Parallax(strengthScene)
      : undefined;
  }, []);

  // Sun parallax for mobile
  const { ref: sunRef } = useParallax<HTMLDivElement>({
    disabled: !isMobile,
    shouldAlwaysCompleteAnimation: true,
    translateY: ["0%", "25%"],
    onProgressChange: (progress: number) => {
      const multiplier = 1 - (1 - progress);
      if (sunRef.current) {
        sunRef.current.style.filter = `brightness(${1 - multiplier})`;
      }
    },
  });

  return (
    <div className="hero-container" ref={heroRef} id="hero-scene">
      <div className="hero-container__brown">
        <div
          id="circles-scene"
          data-input-element="#hero-scene"
          data-hover-only="true"
          data-friction-x="0.04"
          data-precision="2"
        >
          <div className="hero-container__circle-container" data-depth="-0.2">
            <div className="hero-container__circle hero-container__circle--1"></div>
          </div>
          <div className="hero-container__circle-container" data-depth="0.3">
            <div className="hero-container__circle hero-container__circle--2"></div>
          </div>
          <div className="hero-container__circle-container" data-depth="-0.1">
            <div className="hero-container__circle hero-container__circle--3"></div>
          </div>
        </div>
      </div>
      <div className="hero-container__sun">
        <div
          id="sun-scene"
          data-input-element="#hero-scene"
          data-hover-only="true"
          data-friction-x="0.07"
          data-scalar-y="5"
          data-invert-x="false"
          ref={sunRef}
        >
          <div className="hero-container__sun-image" data-depth="0.1"></div>
        </div>
      </div>
      <div className="hero-container__grey">
        <Texture className="hero-container__texture" />
      </div>
      <div className="hero-container__grey-gradient"></div>

      <div className="hero-container__stone">
        <div
          id="stone-scene"
          data-input-element="#hero-scene"
          data-hover-only="true"
        >
          <img
            src={stone_w1168}
            srcSet={`${stone_w584} 584w, ${stone_w1168} 1168w`}
            sizes="584px"
            className="hero-container__stone-image"
            fetchpriority="high"
            alt=""
            data-depth="0.05"
          />
        </div>
      </div>

      <div className="hero-container__geisha">
        <div
          id="geisha-scene"
          data-input-element="#hero-scene"
          data-hover-only="true"
          data-scalar-y="5"
          data-friction-x="0.08"
        >
          <picture className="hero-container__geisha-image" data-depth="0.1">
            <source
              type="image/webp"
              srcSet={`${geisha_w460webp} 460w, ${geisha_w920webp} 920w`}
              sizes="460px"
            />
            <img
              src={geisha_w920png}
              srcSet={`${geisha_w460png} 460w, ${geisha_w920png} 920w`}
              sizes="460px"
              className="hero-container__geisha-image"
              fetchpriority="high"
              alt=""
            />
          </picture>
        </div>
      </div>

      <div className="hero-container__strength">
        <div
          id="strength-scene"
          data-input-element="#hero-scene"
          data-hover-only="true"
        >
          <img
            src={strength_w180}
            srcSet={`${strength_w60} 60w, ${strength_w120} 120w, ${strength_w180} 180w`}
            sizes="60px"
            className="hero-container__strength-image"
            fetchpriority="high"
            alt=""
            data-depth="0.05"
          />
        </div>
      </div>

      {children}
    </div>
  );
}

export default HeroContainer;
