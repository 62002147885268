import clsx from "clsx";
import "styles/elements/HeadingDecoration.scss";

function HeadingDecoration({ src, className }: Props) {
  return (
    <span className={clsx("heading-decoration", className)}>
      <img
        src={src[1].src}
        srcSet={`${src[0].src} ${src[0].size}w, ${src[1].src} ${src[1].size}w`}
        sizes={`${src[0].size}px`}
        className="heading-decoration__image"
        loading="lazy"
        decoding="async"
        alt=""
      />
    </span>
  );
}

type Props = {
  src: [DecorationImage, DecorationImage];
  className?: string;
};

type DecorationImage = {
  size: number;
  src: string;
};

export default HeadingDecoration;
export type { DecorationImage };
