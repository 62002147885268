import "styles/sections/hero/HeroSection.scss";
import { general, hero, sections } from "translations";
import { LangProp } from "types";
import { BrokenLines } from "components";
import { getAnchor } from "utils";
import ScrollDown from "./ScrollDown";
import HeroContainer from "./HeroContainer";
import FigureDetermination from "./FigureDetermination";
import { MutableRefObject, memo } from "react";
import { useScreenResize } from "hooks";
import clsx from "clsx";

const HeroSection = memo(function HeroSection({
  lang,
  startRef,
  heroRef,
}: Props) {
  const { heading, paragraph } = hero[lang];
  const { scrollLabel } = general[lang];
  const nextSection = getAnchor(sections[lang].whyUs.short);
  const isMobile = useScreenResize(688);

  return (
    <>
      <HeroContainer heroRef={heroRef} isMobile={isMobile}>
        {/* if a part of the section is outside of the viewport, the intersection observer does not work */}
        {/* observing a smaller element is a more robust solution for observing the top of the page */}
        <div className="hero__top" ref={startRef}></div>

        <section className="hero" id="start">
          <FigureDetermination className="hero__figure" />

          <div
            className={clsx("hero__text-box", isMobile && "visually-hidden")}
          >
            <h1 className="hero__heading">
              <BrokenLines>{heading}</BrokenLines>
            </h1>
            <p className="hero__paragraph">
              <BrokenLines>{paragraph}</BrokenLines>
            </p>
          </div>

          <div className="hero__scroll-box">
            <ScrollDown
              scrollLabel={scrollLabel}
              nextSection={isMobile ? "#begin" : nextSection}
            />
          </div>
        </section>
      </HeroContainer>

      {isMobile && (
        <div className="hero-mobile" aria-hidden="true" id="begin">
          <h1 className="hero-mobile__heading">
            <BrokenLines>{heading}</BrokenLines>
          </h1>
          <p className="hero-mobile__paragraph">{paragraph}</p>
        </div>
      )}
    </>
  );
});

interface Props extends LangProp {
  startRef: MutableRefObject<HTMLDivElement | null>;
  heroRef: MutableRefObject<HTMLDivElement | null>;
}

export default HeroSection;
