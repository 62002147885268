import "styles/sections/foundation/FoundationAchievements.scss";
import clsx from "clsx";
import { AchievementType, LangProp, Language } from "types";
import { useRef } from "react";
import { useOnScreen } from "hooks";
import NumberAnimated from "components/elements/NumberAnimated";

function FoundationAchievements({ items, lang }: FoundationAchievementsProps) {
  return (
    <ul className="foundation-achievements">
      {items.map(({ number, text }, i) => {
        const isNum = number !== undefined;

        return (
          <li key={i}>
            <p className="foundation-achievements__paragraph">
              {isNum ? <AchievementNumber number={number} lang={lang} /> : ""}
              <span
                className={clsx(
                  "foundation-achievements__text",
                  !isNum && "foundation-achievements__text--small"
                )}
              >
                {text}
              </span>
            </p>
          </li>
        );
      })}
    </ul>
  );
}

function AchievementNumber({
  number,
  lang,
}: {
  number: number | number[];
  lang: Language;
}) {
  const numberRef = useRef<HTMLSpanElement>(null);
  const isVisible = useOnScreen(numberRef, 0.5);
  return (
    <span className="foundation-achievements__number" ref={numberRef}>
      <NumberAnimated animate={isVisible} formatLang={lang}>
        {number}
      </NumberAnimated>
    </span>
  );
}

interface FoundationAchievementsProps extends LangProp {
  items: AchievementType[];
}

export default FoundationAchievements;
