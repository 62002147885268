import "styles/navigation/Logo.scss";
import { useContext } from "react";
import { SarigatoLogoCircle, SarigatoLogoText } from "images-react";
import { companyDetails, general } from "translations";
import { LangProp } from "types";
import clsx from "clsx";
import { useLenis } from "@studio-freight/react-lenis";
import { DeviceContext } from "components/App";

function Logo({ lang, isPageTop, navIsExpanded }: Props) {
  const { toTopLabel, toTopSlug } = general[lang];
  const { shortName } = companyDetails[lang];
  const anchor = "#" + toTopSlug;
  const lenis = useLenis();
  const isMobileOrTablet = useContext(DeviceContext);

  const handleLinkClick = () => {
    if (isMobileOrTablet) return;
    lenis.scrollTo(anchor, { duration: 4 });
  };

  return (
    <div className={clsx("logo", !isPageTop && !navIsExpanded && "combined")}>
      <a className="logo__link" href={anchor} onClick={handleLinkClick}>
        <SarigatoLogoCircle
          className="logo__circle"
          role="img"
          title={shortName + " - " + toTopLabel}
        />
        <SarigatoLogoText
          className={clsx(
            "logo__text",
            navIsExpanded && "logo__text--force-dark"
          )}
          role="presentation"
        />
      </a>
    </div>
  );
}

interface Props extends LangProp {
  isPageTop: boolean;
  navIsExpanded: boolean;
}

export default Logo;
