import { splitByNumber } from "utils";
import CountUpAnimation from "./CountUpAnimation";
import { Language } from "types";

function NumberAnimated({
  children,
  animate,
  formatLang,
  duration = 2000,
}: Props) {
  if (typeof children === "string") {
    const text = splitByNumber(children);
    if (!text) return <>{children}</>;
    const { start, number, end } = text;

    return (
      <>
        <span>{start}</span>
        <CountUpAnimation
          duration={duration}
          animate={animate}
          formatLang={formatLang}
        >
          {number}
        </CountUpAnimation>
        <span>{end}</span>
      </>
    );
  } else if (typeof children === "number") {
    return (
      <CountUpAnimation
        duration={duration}
        animate={animate}
        formatLang={formatLang}
      >
        {children}
      </CountUpAnimation>
    );
  } else {
    return (
      <>
        <CountUpAnimation
          duration={duration}
          animate={animate}
          formatLang={formatLang}
        >
          {children[0]}
        </CountUpAnimation>
        {" / "}
        <CountUpAnimation
          duration={duration}
          animate={animate}
          formatLang={formatLang}
        >
          {children[1]}
        </CountUpAnimation>
      </>
    );
  }
}

type Props = {
  children: string | number | number[];
  animate: boolean;
  formatLang?: Language;
  duration?: number;
  padSize?: number;
};

export default NumberAnimated;
