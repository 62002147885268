import "styles/sections/credibility/CredibilityAwards.scss";
import CredibilityAwardImage from "./CredibilityAwardImage";

// images
const award2015png_w119 = "images-react/badges/2015_w119.png";
const award2015png_w239 = "images-react/badges/2015_w239.png";
const award2015png_w358 = "images-react/badges/2015_w358.png";

const award2017png_w144 = "images-react/badges/2017_w144.png";
const award2017png_w288 = "images-react/badges/2017_w288.png";
const award2017png_w432 = "images-react/badges/2017_w432.png";

const award2018png_w119 = "images-react/badges/2018_w119.png";
const award2018png_w239 = "images-react/badges/2018_w239.png";
const award2018png_w358 = "images-react/badges/2018_w358.png";

const award2019png_w119 = "images-react/badges/2019_w119.png";
const award2019png_w239 = "images-react/badges/2019_w239.png";
const award2019png_w358 = "images-react/badges/2019_w358.png";

const award2020png_w119 = "images-react/badges/2020_w119.png";
const award2020png_w239 = "images-react/badges/2020_w239.png";
const award2020png_w358 = "images-react/badges/2020_w358.png";

const award2022png_w120 = "images-react/badges/2022_w120.png";
const award2022png_w240 = "images-react/badges/2022_w240.png";
const award2022png_w360 = "images-react/badges/2022_w360.png";

const award2023png_w120 = "images-react/badges/2023_w120.png";
const award2023png_w240 = "images-react/badges/2023_w240.png";
const award2023png_w360 = "images-react/badges/2023_w360.png";

type AwardImage = { size: number; png: string };

const awardImages: { [key: string]: AwardImage[] } = {
  award2015: [
    { size: 119, png: award2015png_w119 },
    { size: 239, png: award2015png_w239 },
    { size: 358, png: award2015png_w358 },
  ],
  award2017: [
    { size: 144, png: award2017png_w144 },
    { size: 288, png: award2017png_w288 },
    { size: 432, png: award2017png_w432 },
  ],
  award2018: [
    { size: 119, png: award2018png_w119 },
    { size: 239, png: award2018png_w239 },
    { size: 358, png: award2018png_w358 },
  ],
  award2019: [
    { size: 119, png: award2019png_w119 },
    { size: 239, png: award2019png_w239 },
    { size: 358, png: award2019png_w358 },
  ],
  award2020: [
    { size: 119, png: award2020png_w119 },
    { size: 239, png: award2020png_w239 },
    { size: 358, png: award2020png_w358 },
  ],
  award2022: [
    { size: 120, png: award2022png_w120 },
    { size: 240, png: award2022png_w240 },
    { size: 360, png: award2022png_w360 },
  ],
  award2023: [
    { size: 120, png: award2023png_w120 },
    { size: 240, png: award2023png_w240 },
    { size: 360, png: award2023png_w360 },
  ],
};

interface Props {
  images: Image[];
  label: string;
}

interface Image {
  src: string;
  alt: string;
}

function CredibilityAwards({ images, label }: Props) {
  return (
    <figure
      className="credibility-awards"
      role="group"
      aria-labelledby="awards-caption"
    >
      <figcaption className="visually-hidden" id="awards-caption">
        {label}
      </figcaption>

      {images.map(({ src, alt }, i) => (
        <CredibilityAwardImage
          src={awardImages["award" + src]}
          alt={alt}
          i={i}
          key={i}
        />
      ))}
    </figure>
  );
}

export default CredibilityAwards;
export type { AwardImage };
