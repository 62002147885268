/* eslint-disable @typescript-eslint/no-unused-vars */
import "styles/App.scss";
import { useState, useEffect, useRef, useCallback, createContext } from "react";
import { useLanguage, useOnScreen } from "hooks";
import { getAnchor, slugify, updateMetaData, detectDevice } from "utils";
import { metaData, sections } from "translations";
import { Language, Section, SectionState } from "types";
import {
  Footer,
  Header,
  HeroSection,
  ReviewsSection,
  ContactSection,
  ClientsSection,
  TeamSection,
  FoundationSection,
  CredibilitySection,
  WhyUsSection,
  WhatWeDoSection,
} from "components";
import { ParallaxProvider } from "react-scroll-parallax";
import { ReactLenis } from "@studio-freight/react-lenis";

// const availableLanguages: Language[] = ["en", "pl"];
const availableLanguages: Language[] = ["pl"];

const isMobileOrTablet = detectDevice();
const DeviceContext = createContext(isMobileOrTablet);

function App() {
  const [appLanguage, changeAppLanguage] = useLanguage(availableLanguages);
  const [currentSection, setCurrentSection] = useState<SectionState>("");
  const { title, description } = metaData[appLanguage];
  const sectionsTranslated: { [key: string]: Section } = sections[appLanguage];
  const startRef = useRef(null);
  const heroRef = useRef(null);
  const isPageTop = useOnScreen(startRef, 1);
  const isHero = useOnScreen(heroRef, 0.1);

  // observe sections
  const contactSectionRef = useRef(null);
  const reviewsSectionRef = useRef(null);
  const clientsSectionRef = useRef(null);
  const teamSectionRef = useRef(null);
  const foundationSectionRef = useRef(null);
  const credibilitySectionRef = useRef(null);
  const whyUsSectionRef = useRef(null);
  const whatWeDoSectionRef = useRef(null);
  const isContactSection = useOnScreen(contactSectionRef, 0.5);
  const isReviewsSection = useOnScreen(reviewsSectionRef, 0.5);
  const isClientsSection = useOnScreen(clientsSectionRef, 0.5);
  const isTeamSection = useOnScreen(teamSectionRef, 0.5);
  const isFoundationSection = useOnScreen(foundationSectionRef, 0.5);
  const isCredibilitySection = useOnScreen(credibilitySectionRef, 0.5);
  const isWhyUsSection = useOnScreen(whyUsSectionRef, 0.5);
  const isWhatWeDoSection = useOnScreen(whatWeDoSectionRef, 0.5);

  useEffect(() => {
    if (isPageTop) {
      if (currentSection === "") return;
      setCurrentSection("start");
    }
    if (isClientsSection) setCurrentSection("clients");
    if (isReviewsSection) setCurrentSection("reviews");
    if (isContactSection) setCurrentSection("contact");
    if (isTeamSection) setCurrentSection("team");
    if (isFoundationSection) setCurrentSection("foundation");
    if (isCredibilitySection) setCurrentSection("credibility");
    if (isWhyUsSection) setCurrentSection("whyUs");
    if (isWhatWeDoSection) setCurrentSection("whatWeDo");
  }, [
    isClientsSection,
    isContactSection,
    isReviewsSection,
    isTeamSection,
    isPageTop,
    isFoundationSection,
    isCredibilitySection,
    isWhyUsSection,
    isWhatWeDoSection,
    currentSection,
  ]);

  const updateLocationHash = useCallback(() => {
    const hash = window.location.hash;
    Object.entries(sectionsTranslated).forEach(([key, section]) => {
      if (getAnchor(section.short) === hash) {
        setCurrentSection(key);
        return;
      }
    });
  }, [sectionsTranslated]);

  useEffect(() => {
    updateMetaData({ language: appLanguage, title, description });
    if (currentSection !== "") {
      window.location.hash = slugify(sectionsTranslated[currentSection].short);
    }
    // don't include currentSection in the dependencies array
  }, [appLanguage, title, description, sectionsTranslated]); // eslint-disable-line

  useEffect(() => {
    updateLocationHash();
    window.addEventListener("hashchange", () => {
      updateLocationHash();
    });
  }, [updateLocationHash]);

  return (
    <DeviceContext.Provider value={isMobileOrTablet}>
      <Lenis disable={isMobileOrTablet}>
        <ParallaxProvider>
          <Header
            lang={appLanguage}
            onLangChange={changeAppLanguage}
            sections={sectionsTranslated}
            currentSection={currentSection}
            isPageTop={isPageTop}
            isHero={isHero}
          />

          <main>
            <HeroSection
              lang={appLanguage}
              startRef={startRef}
              heroRef={heroRef}
            />
            <WhyUsSection
              lang={appLanguage}
              label={sectionsTranslated["whyUs"]}
              refer={whyUsSectionRef}
            />
            <WhatWeDoSection
              lang={appLanguage}
              label={sectionsTranslated["whatWeDo"]}
              refer={whatWeDoSectionRef}
            />
            <CredibilitySection
              lang={appLanguage}
              label={sectionsTranslated["credibility"]}
              refer={credibilitySectionRef}
            />
            <FoundationSection
              lang={appLanguage}
              label={sectionsTranslated["foundation"]}
              refer={foundationSectionRef}
            />
            <ClientsSection
              lang={appLanguage}
              label={sectionsTranslated["clients"]}
              refer={clientsSectionRef}
            />
            <TeamSection
              label={sectionsTranslated["team"]}
              lang={appLanguage}
              refer={teamSectionRef}
            />
            {/* <ReviewsSection
              lang={appLanguage}
              label={sectionsTranslated["reviews"]}
              refer={reviewsSectionRef}
            /> */}
            <ContactSection
              lang={appLanguage}
              label={sectionsTranslated["contact"]}
              refer={contactSectionRef}
            />
            <Footer lang={appLanguage} />
          </main>
        </ParallaxProvider>
      </Lenis>
    </DeviceContext.Provider>
  );
}

function Lenis({
  disable,
  children,
}: {
  disable: boolean;
  children: React.ReactNode;
}) {
  if (disable) return <>{children}</>;
  return (
    <ReactLenis root options={{ duration: 2 }}>
      {children}
    </ReactLenis>
  );
}

export default App;
export { availableLanguages, DeviceContext };
