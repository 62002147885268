import "styles/navigation/LanguageNav.scss";
import { availableLanguages } from "components/App";
import { general } from "translations";
import { LangChangeHandler, LangProp } from "types";
import { LanguageItem } from "components/navigation";

function LanguageNav({ lang: appLang, onLangChange, expanded }: Props) {
  const { languageChangeLabel } = general[appLang];
  const languageLinks = availableLanguages.map((lang) => {
    const isCurrentLang = lang === appLang;
    const fullLang = general[lang].language;

    return (
      <li className="language-nav__item" key={lang}>
        <LanguageItem
          lang={lang}
          fullLang={fullLang}
          onLangChange={onLangChange}
          current={isCurrentLang}
          expanded={expanded}
        />
      </li>
    );
  });

  return (
    <nav className="language-nav" aria-label={languageChangeLabel}>
      <ul className="language-nav__list">{languageLinks}</ul>
    </nav>
  );
}

interface Props extends LangProp {
  onLangChange: LangChangeHandler;
  expanded: boolean;
}

export default LanguageNav;
