import "styles/footer/Footer.scss";
import "styles/footer/FooterSocialMedia.scss";
import { useParallax } from "react-scroll-parallax";
import { memo, useContext } from "react";
import { LangProp } from "types";
import { ArrowUp, OrigamiCrane, SarigatoGroupLogo } from "images-react";
import { companyDetails, general } from "translations";
import SocialMedia from "components/elements/SocialMedia";
import YearAndCompanyName from "components/elements/YearAndCompanyName";
import Offices from "./Offices";
import { useLenis } from "@studio-freight/react-lenis";
import { DeviceContext } from "components/App";

const Footer = memo(function Footer({ lang }: LangProp) {
  const isMobileOrTablet = useContext(DeviceContext);
  const { shortName } = companyDetails[lang];
  const { copyright, toTopLabel, privacyPolicy } = general[lang];
  const { ref: sunRef } = useParallax<HTMLDivElement>({
    shouldAlwaysCompleteAnimation: true,
    translateY: ["-15%", "0%"],
    onProgressChange: (progress: number) => {
      const multiplier = (1 - (1 - progress)) ** 4;
      if (sunRef.current)
        sunRef.current.style.background = `linear-gradient(
          to top,
          transparent,
          rgb(230 28 98 / 0.43) 57.2%,
          rgb(234 ${200 - multiplier * (200 - 32)} 70)
        )`;
    },
  });

  const lenis = useLenis();
  const handleToTopClick = () => {
    if (isMobileOrTablet) return;
    lenis.scrollTo("#start", { duration: 4 });
  };

  return (
    <footer className="footer">
      <div className="footer__background"></div>
      <div className="footer__sun-blur-box"></div>
      <div className="footer__sun-container">
        <div className="footer__sun" ref={sunRef}></div>
      </div>
      <div className="footer__container">
        <div className="footer__flex-container footer__flex-container--left">
          <Offices lang={lang} />
        </div>
        <div className="footer__flex-container footer__flex-container--middle">
          <a
            href="#start"
            className="footer__origami-container"
            onClick={handleToTopClick}
          >
            <OrigamiCrane
              className="footer__origami"
              role="presentation"
              aria-hidden="true"
            />
            <span className="footer__top-link">
              <ArrowUp
                className="footer__top-link-image"
                role="img"
                title={toTopLabel}
              />
            </span>
          </a>
          <p className="footer__copyright">
            <YearAndCompanyName lang={lang} /> {copyright}
            <br />
            <a
              className="footer__privacy-link"
              href="/polityka_prywatnosci_sarigato_sataku.pdf"
              hrefLang="pl"
              target="_blank"
              rel="noopener noreferrer"
            >
              {privacyPolicy}
            </a>
          </p>
        </div>
        <div className="footer__flex-container footer__flex-container--right">
          <SocialMedia lang={lang} classModifier="footer" />
          <div className="footer__logo-container">
            <SarigatoGroupLogo
              className="footer__logo"
              role="img"
              title={shortName}
            />
          </div>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
