import "styles/sections/contact/ContactPeople.scss";
import { contact } from "translations";
import { LangProp } from "types";
import ContactPerson from "./ContactPerson";
import { useParallax } from "react-scroll-parallax";
import { DeviceContext } from "components/App";
import { useContext } from "react";
import { useScreenResize } from "hooks";

const brush_w1306 = "images-react/backgrounds/brush_w1306.png";
const brush_w2609 = "images-react/backgrounds/brush_w2609.png";

function ContactPeople({ lang }: LangProp) {
  const isMobileOrTablet = useContext(DeviceContext);
  const isSmallScreen = useScreenResize(688);
  const { ref: person1 } = useParallax<HTMLLIElement>({
    translateX: ["-500px", "0px", "easeOutExpo"],
    opacity: [0, 1, "easeOutExpo"],
    rootMargin: { top: 0, right: 0, bottom: -500, left: 0 },
    disabled: isMobileOrTablet || isSmallScreen,
  });
  const { ref: person2 } = useParallax<HTMLLIElement>({
    translateX: ["500px", "0px", "easeOutExpo"],
    opacity: [0, 1, "easeOutExpo"],
    rootMargin: { top: 0, right: 0, bottom: -500, left: 0 },
    disabled: isMobileOrTablet || isSmallScreen,
  });
  const refs = [person1, person2];

  const bgOptions =
    isMobileOrTablet || isSmallScreen
      ? {
          translateX: ["-100px", "100px"],
          translateY: ["-100px", "100px"],
        }
      : { speed: -8 };

  const { ref: bgRef } = useParallax<HTMLImageElement>(bgOptions as any);

  const { contacts } = contact[lang];
  const contactList = contacts.map((personDetails, i) => (
    <ContactPerson
      key={personDetails.email}
      details={personDetails}
      refer={refs[i]}
    />
  ));

  return (
    <address className="contact-people">
      <ul className="contact-people__list">{contactList}</ul>
      <div className="contact-people__background-container">
        <img
          src={brush_w2609}
          srcSet={`${brush_w1306} 1306w, ${brush_w2609} 2609w`}
          sizes="1306px"
          className="contact-people__background-image"
          loading="lazy"
          decoding="async"
          alt=""
          ref={bgRef}
        />
      </div>
    </address>
  );
}

export default ContactPeople;
