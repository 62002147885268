import { Fragment } from "react";

function StrongText({ children: text }: Props) {
  const parts = text.split("**").map((part, i) => {
    if (i % 2 !== 0) return <strong key={i}>{part}</strong>;
    else return <Fragment key={i}>{part}</Fragment>;
  });

  return <>{parts}</>;
}

interface Props {
  children: string;
}

export default StrongText;
