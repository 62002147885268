import { Fragment } from "react";

function NonBreakableSpaces({ children: text }: Props) {
  const textWithEntities = text.split("&nbsp;").map((part, i, arr) => {
    const len = arr.length;
    const isLast = i === len - 1;
    const lastPart = isLast ? "" : <>&nbsp;</>;
    return (
      <Fragment key={i}>
        {part}
        {lastPart}
      </Fragment>
    );
  });
  return <>{textWithEntities}</>;
}

type Props = {
  children: string;
};

export default NonBreakableSpaces;
