export default function splitByNumber(text: string) {
  const textArr = Array.from(text) as string[];
  const regExp = /[0-9]/;

  const numberStart = textArr.findIndex((char: string) => regExp.test(char));
  if (numberStart === -1) return undefined;

  let numberEnd;
  let i = text.length - 1;

  while (numberEnd === undefined) {
    if (regExp.test(text[i])) numberEnd = i + 1;
    i--;
  }

  const textStart = text.slice(0, numberStart);
  const textNumber = text.slice(numberStart, numberEnd);
  const textEnd = text.slice(numberEnd);

  return {
    start: textStart,
    number: textNumber,
    end: textEnd,
  };
}
