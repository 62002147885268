import "styles/sections/team/TeamGallery.scss";
import { GalleryItem } from "types";
import { useParallax } from "react-scroll-parallax";

const image01_w536jpg = "images-react/team/01_w536.jpg";
const image01_w536webp = "images-react/team/01_w536.webp";
const image01_w1072jpg = "images-react/team/01_w1072.jpg";
const image01_w1072webp = "images-react/team/01_w1072.webp";
const image01_w1608jpg = "images-react/team/01_w1608.jpg";
const image01_w1608webp = "images-react/team/01_w1608.webp";

const image02_w398jpg = "images-react/team/02_w398.jpg";
const image02_w398webp = "images-react/team/02_w398.webp";
const image02_w796jpg = "images-react/team/02_w796.jpg";
const image02_w796webp = "images-react/team/02_w796.webp";
const image02_w1194jpg = "images-react/team/02_w1194.jpg";
const image02_w1194webp = "images-react/team/02_w1194.webp";

const image03_w536jpg = "images-react/team/03_w536.jpg";
const image03_w536webp = "images-react/team/03_w536.webp";
const image03_w1072jpg = "images-react/team/03_w1072.jpg";
const image03_w1072webp = "images-react/team/03_w1072.webp";
const image03_w1608jpg = "images-react/team/03_w1608.jpg";
const image03_w1608webp = "images-react/team/03_w1608.webp";

const image04_w398jpg = "images-react/team/04_w398.jpg";
const image04_w398webp = "images-react/team/04_w398.webp";
const image04_w796jpg = "images-react/team/04_w796.jpg";
const image04_w796webp = "images-react/team/04_w796.webp";
const image04_w1194jpg = "images-react/team/04_w1194.jpg";
const image04_w1194webp = "images-react/team/04_w1194.webp";

const image05_w674jpg = "images-react/team/05_w674.jpg";
const image05_w674webp = "images-react/team/05_w674.webp";
const image05_w1348jpg = "images-react/team/05_w1348.jpg";
const image05_w1348webp = "images-react/team/05_w1348.webp";
const image05_w2022jpg = "images-react/team/05_w2022.jpg";
const image05_w2022webp = "images-react/team/05_w2022.webp";

const image06_w674jpg = "images-react/team/06_w674.jpg";
const image06_w674webp = "images-react/team/06_w674.webp";
const image06_w1348jpg = "images-react/team/06_w1348.jpg";
const image06_w1348webp = "images-react/team/06_w1348.webp";
const image06_w2022jpg = "images-react/team/06_w2022.jpg";
const image06_w2022webp = "images-react/team/06_w2022.webp";

const image07_w536jpg = "images-react/team/07_w536.jpg";
const image07_w536webp = "images-react/team/07_w536.webp";
const image07_w1072jpg = "images-react/team/07_w1072.jpg";
const image07_w1072webp = "images-react/team/07_w1072.webp";

const image08_w812jpg = "images-react/team/08_w812.jpg";
const image08_w812webp = "images-react/team/08_w812.webp";
const image08_w1624jpg = "images-react/team/08_w1624.jpg";
const image08_w1624webp = "images-react/team/08_w1624.webp";

const images: { [key: string]: { size: number; jpg: string; webp: string }[] } =
  {
    image01: [
      { size: 536, jpg: image01_w536jpg, webp: image01_w536webp },
      { size: 1072, jpg: image01_w1072jpg, webp: image01_w1072webp },
      { size: 1608, jpg: image01_w1608jpg, webp: image01_w1608webp },
    ],
    image02: [
      { size: 398, jpg: image02_w398jpg, webp: image02_w398webp },
      { size: 796, jpg: image02_w796jpg, webp: image02_w796webp },
      { size: 1194, jpg: image02_w1194jpg, webp: image02_w1194webp },
    ],
    image03: [
      { size: 536, jpg: image03_w536jpg, webp: image03_w536webp },
      { size: 1072, jpg: image03_w1072jpg, webp: image03_w1072webp },
      { size: 1608, jpg: image03_w1608jpg, webp: image03_w1608webp },
    ],
    image04: [
      { size: 398, jpg: image04_w398jpg, webp: image04_w398webp },
      { size: 796, jpg: image04_w796jpg, webp: image04_w796webp },
      { size: 1194, jpg: image04_w1194jpg, webp: image04_w1194webp },
    ],
    image05: [
      { size: 674, jpg: image05_w674jpg, webp: image05_w674webp },
      { size: 1348, jpg: image05_w1348jpg, webp: image05_w1348webp },
      { size: 2022, jpg: image05_w2022jpg, webp: image05_w2022webp },
    ],
    image06: [
      { size: 674, jpg: image06_w674jpg, webp: image06_w674webp },
      { size: 1348, jpg: image06_w1348jpg, webp: image06_w1348webp },
      { size: 2022, jpg: image06_w2022jpg, webp: image06_w2022webp },
    ],
    image07: [
      { size: 536, jpg: image07_w536jpg, webp: image07_w536webp },
      { size: 1072, jpg: image07_w1072jpg, webp: image07_w1072webp },
    ],
    image08: [
      { size: 812, jpg: image08_w812jpg, webp: image08_w812webp },
      { size: 1624, jpg: image08_w1624jpg, webp: image08_w1624webp },
    ],
  };

function TeamGallery({ items, label }: Props) {
  const id = "team-gallery-heading";
  const parallaxOptions = {
    translateY: ["400px", "0px", "easeOutExpo"] as any,
    opacity: [0, 1, "easeOutQuint"] as any,
    rootMargin: { top: 0, right: 0, bottom: 0, left: 0 },
    scaleY: [2, 1, "easeOutExpo"] as any,
  };

  const { ref: image1 } = useParallax<HTMLDivElement>({});
  const { ref: image2 } = useParallax<HTMLDivElement>({});
  const { ref: image3 } = useParallax<HTMLDivElement>({
    ...parallaxOptions,
  });
  const { ref: image4 } = useParallax<HTMLDivElement>({
    ...parallaxOptions,
    translateY: ["800px", "0px", "easeOutExpo"],
  });
  const { ref: image5 } = useParallax<HTMLDivElement>({
    ...parallaxOptions,
    translateY: ["1200px", "0px", "easeOutExpo"],
  });
  const { ref: image6 } = useParallax<HTMLDivElement>({
    ...parallaxOptions,
  });
  const { ref: image7 } = useParallax<HTMLDivElement>({
    ...parallaxOptions,
    translateY: ["1200px", "0px", "easeOutExpo"],
  });
  const { ref: image8 } = useParallax<HTMLDivElement>({ ...parallaxOptions });
  const { ref: image9 } = useParallax<HTMLDivElement>({
    ...parallaxOptions,
    translateY: ["800px", "0px", "easeOutExpo"],
  });
  const imgRefs = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];

  return (
    <section className="team-gallery" aria-labelledby={id}>
      <h4 className="visually-hidden" id={id}>
        {label}
      </h4>
      {items.map((item, i) => {
        let webpSrcs = "";
        let jpgSrcs = "";

        if (item.type === "image") {
          images[`image${item.src}`].forEach(({ size, webp, jpg }, i, arr) => {
            let last = ", ";
            if (i === arr.length - 1) last = "";
            webpSrcs += `${webp} ${size}w` + last;
            jpgSrcs += `${jpg} ${size}w` + last;
          });
        }

        return (
          <div
            className={`team-gallery__item team-gallery__item--${i + 1}`}
            key={item.src}
            ref={imgRefs[i]}
          >
            {item.type === "image" ? (
              <picture>
                <source
                  type="image/webp"
                  srcSet={webpSrcs}
                  sizes={`${images[`image${item.src}`][0].size}px`}
                />
                <img
                  src={
                    images[`image${item.src}`][
                      images[`image${item.src}`].length - 1
                    ].jpg
                  }
                  srcSet={jpgSrcs}
                  sizes={`${images[`image${item.src}`][0].size}px`}
                  className={`team-gallery__image team-gallery__image--${
                    i + 1
                  }`}
                  alt={item.alt}
                />
              </picture>
            ) : (
              <object width="560" height="315">
                <param name="movie" value={item.src} />
                <embed
                  className="team-gallery__video"
                  src={item.src}
                  width="560"
                  height="315"
                />
              </object>
            )}
          </div>
        );
      })}
    </section>
  );
}

type Props = {
  items: GalleryItem[];
  label: string;
};

export default TeamGallery;
