import { NewLine } from "components";

function BrokenLines({ children: text }: Props) {
  const textLines = text.split("\n").map((textLine, i, arr) => {
    const isLastLine = i === arr.length - 1;
    const formattedTextLine = textLine + (isLastLine ? "" : " ");

    return <NewLine key={i}>{formattedTextLine}</NewLine>;
  });

  return <>{textLines}</>;
}

type Props = {
  children: string;
};

export default BrokenLines;
